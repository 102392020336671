#manifesto h1 {
    color: var(--green);
}

#manifesto {
    margin-top: 230px;
}

.manifesto-description {
    width: 100%;
    margin-top: 30px;
}

.rules {
    margin-top: 200px;
    color: var(--light-grey);
}

.rule {
    width: 100%;
    min-height: 300px;
    padding: 0px 125px 0px 164px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    margin-top: 50px;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
}

.rule.background .rule-icon,
.rule.background .rule-description {
    background: var(--light-black);
    background-size: 80%;
}

.rule:first-child {
    margin-top: 0;
}

.rule-heading {
    display: flex;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 131%;
    color: var(--green);
    align-items: center;
}

.rule-heading .rule-number {
    color: #86888B;
}

.rule-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rule-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding-right: 60px;
}


.rule-icon,
.rule-description {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.rule:hover .rule-icon,
.rule:hover .rule-description {
    background: var(--light-black);
    background-size: 80%;
}

.rule:hover .rule-icon{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rule:hover .rule-description{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rule:hover .rule-description p{
    scale: 1.01;
}

.rule:hover .rule-text p{
    color: var(--white)!important;
}

.rule-description p{
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
}

.rule-text p{
    color: #DDDDDD;
}

@media only screen and (max-width: 1401px) {
    #manifesto .body6 {
        font-size: 19px;
    }

    #manifesto .rule-heading {
        font-size: 35px;
    }
}

@media only screen and (max-width: 1201px) {
    .rule {
        padding-inline: 50px;
    }
}

@media only screen and (max-width: 992px) {

    #manifesto .body6 {
        font-size: 15px;
    }

    #manifesto .rule-heading {
        font-size: 30px;
    }

    .rule-icon img {
        max-width: 70px;
    }

    .rule {
        height: 200px;
    }

    #manifesto .heading h1 {
        font-size: 40px !important;
    }

    #manifesto .body1 {
        font-size: 30px !important;
    }

    #manifesto {
        margin-top: 150px;
    }

    .rules {
        margin-top: 100px;
    }
    .rule-description{
        padding-right: 30px;
    }
}

@media only screen and (max-width: 768px) {

    #manifesto h1,
    .manifesto-description {
        padding-inline: 30px;
    }

    .rule {
        display: block;
        height: auto;
        padding: 20px 30px;
    }

    .rule-description {
        display: block;
        padding-top: 40px;
        padding: 15px;
    }

    .rule.background {
        background-color: var(--light-black);
    }

    .rule-icon img {
        max-width: 200px;
    }

    .rule-icon {
        padding-top: 15px;
        justify-content: flex-start;
    }

    .rule-description .body6 {
        font-size: 20px !important;
    }

    .rule-icon {
        justify-content: center;
    }

    .rules {
        margin-top: 50px;
    }

    #manifesto .heading h1 {
        font-size: 50px !important;
    }

    #manifesto .body1 {
        font-size: 25px !important;
    }

    #manifesto {
        margin-top: 100px;
    }
}