.logo-footer{
    height: 30px;
}

.footer{
    padding-top: 25px!important;
    padding-bottom: 25px!important;
    border-top: 1px solid var(--dark-blue);
    background: var(--background);
}

.footer a{
    color: var(--white);
    text-decoration: none;
}

.footer a:hover{
    color: var(--white);
    text-decoration: none;
}

.footer-links a{
    width: 100%;
    text-align: left;
}

.footer-links a:hover{
    color: var(--white);
}

.footer-links a, .rights{
    color: #DDDDDD;
}

.footer-list{
    display: contents;
    list-style: none;
} 

@media only screen and (min-width: 992px) {
    .footer-links a{
        text-align: center;
    }
}

@media only screen and (max-width: 769px) {
    .footer-list{
        display: list-item;
    } 
}