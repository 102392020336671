.viewmode-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--grey);
    opacity: .3;
    cursor: pointer;
}

.close-btn {
    position: absolute;
    z-index: 100;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.close-btn img{

    width: 10px;
    height: 10px;
}

.event-image {
    position: absolute;
    width: 60%;
    height: auto;
    top: 50%;
    left: 50%;
}

.carousel{
    height: 250px;
    overflow: hidden;
}

.swiper{
    margin-top: 100px;
    margin-bottom: 100px;
}

.swiper-wrapper{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 770px){
    .gallery-image{
        height: auto;
    }
}

@media only screen and (max-width: 768px)  {
    #event .container{
        padding-top: 100px!important;
    }
    .heading h1, .event-name h1{
        font-size: 50px!important;
    }
}


@media only screen and (max-width: 480px)  {
    .heading h1, .event-name h1{
        font-size: 40px!important;
    }
    .image-container{
        padding-top: 20px;
    }
}