#welcome {
    z-index: 1;
    display: flex;
}

#welcome p {
    margin: 0;
}

.welcome-text {
    font-size: 40px;
    text-align: center;
}

.welcome-text p {
    text-align: center;
}

.gradient-text {
    background: linear-gradient(to left, #AFFD4B, #99CC61, #E8E8E8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 10s linear infinite;
    background-size: 200% auto;
}

#welcome h4{
    line-height: 140%!important;
}

@keyframes shine {
    to {
        background-position: 200% top;
    }
}
