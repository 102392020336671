.project-small {
    background-color: var(--light-black);

}

.projects-text {
    width: 1160px !important;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 135%;
    color: #DDDDDD !important;
}

.section-margin-100 {
    margin-top: 100px;
}

.project-name {
    grid-area: 1 / 1 / 2 / 2;
}

.project-links {
    align-items: self-start;
    display: flex;
    flex-direction: column;
    justify-content: end;
    grid-area: 2 / 1 / 3 / 2;
}

.project-links-link {
    margin-top: 30px;
}

.project-links-link a {
    color: var(--white);
    text-decoration: none;
}

.project-links-link a:hover {
    color: var(--white);
    text-decoration: none;
}

.project-big .project-link-heading {
    background: var(--green);
    padding: 3px 6px;
    border-radius: 50px;
}

.project-card-logo{
    position: relative;
    width: 100%;
}

.project-link{
    height: 40px;
}

.logo {
    color: var(--white);
}

.company-logo{
    width: 200px;
    position: absolute;
    top: -45px;
}

.project-box:hover .project {
    background-color: var(--green);
    color: var(--black);
    height: 564px !important;
    background-image: url('./logos/background.svg');
    transition-duration: 0.3s;
    bottom: 0;
    width: 100%;
}

.project-box:hover .logo {
    color: var(--black);
    filter: brightness(0);
    transition-duration: 1s;
}

.project-box a {
    transition-duration: 1s;
}

.project-box a {
    color: var(--white);
}

.project-box:hover a {
    color: var(--black);
    transition-duration: 1s;
}

.project-box:hover .project-link-heading,
.project-box:hover .project-links-link {
    background-color: var(--green);
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    transition-delay: 0.3s;
    border-radius: 10px;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    max-width: 1640px;
    margin: auto;
}

#projects.container{
    max-width: 1640px!important;
}

.project-card {
    overflow: hidden;
    width: 100%;
    height: 464px;
    background-color: var(--light-black);
    background-image: url('./logos/background.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 0;
    border-radius: 5px;
    padding: 70px 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    transition-duration: 0.5s;
}

.project-card:hover{
    height: 564px;
    background-color: var(--green);
    background-size: cover;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.project-card:hover .project-card-link a{
    background-color: var(--green);
    color: var(--black);
    transition: background-color 0.2s linear 0.3s;
    border-radius: 10px;
}

.project-card-description p{
    margin-bottom: 0;
}

.project-card:hover .project-card-description span{
    border-radius: 10px;
    background-color: var(--green);
    color: var(--black);
    transition: background-color 0.2s linear 0.3s;
}

.project-card:hover .project-card-name a{
    color: var(--black);
}

.project-card:hover .project-card-logo{
    filter: brightness(0);
}

.project-card-info{
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.project-card-link{
    margin-top: 26px;
}

.project-card-link a {
    text-decoration: none;
    color: var(--green);
}

.project-card-name a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--white);
}

.project-card-info{
    color: var(--green);
}

.blur-logo {
    width: 70%;
    height: 60px;
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    animation-duration: 13s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #fafafa7d 8%, #ffffffb8 38%, #fafafa7d 54%);
}

@keyframes placeHolderShimmer{
    0%{
        background-position: 0px 0;
    }
    100%{
        background-position: 100em 0;
    }
}