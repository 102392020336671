.format1,.format2{
    color: var(--light-grey);
}

.format1:hover,.format2:hover{
    color: var(--white);
}

.link-back{
    color: var(--grey);
}

.link-back:hover{
    color: var(--white);
}

#events h1 {
    color: var(--green);
}

#events {
    margin-top: 230px;
}

.events-description {
    width: 100%;
    margin-top: 30px;
}

.choose-events-btn {
    display: flex;
    justify-content: center;
    margin-top: 102px;
}

.visiting {
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.visiting img {
    border-radius: 5px;
}

.visiting-image {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    display: flex;
    align-items: start;
    justify-content: center;
}

.visiting-box {
    max-width: 1370px;
    display: grid;
    grid-template-columns: 44% 51%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 100px;
    padding-bottom: 100px;
    border-bottom: 3px solid var(--grey);
    justify-content: space-between;
}

.visiting-box:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.visiting-box:first-child {
    margin-top: 0;
}

.visiting a {
    font-size: 25px;
    color: var(--white);
    text-decoration: none!important;
}

.visiting h4{
    transition: 0.3s ease-in-out;
}

.visiting a:hover h4{
    color: #DDDDDD;
}

.visiting-info {
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.visiting-date {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 30px !important;
    color: var(--grey);
}

.visiting-name h4 {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 50px !important;
    color: var(--green);
}

.visiting-place {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 36px !important;
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: var(--light-grey);
}

.event-btn {
    cursor: pointer;
    display: inline-block;
    transition: .3s ease-out;
    color: var(--grey);
}

.choose-events-btn div:first-child {
    margin-right: 52px;
}

.event-btn.active {
    color: var(--green);
}

.event-btn:hover {
    color: var(--white);
    text-decoration: none;
}

.choose-events-year {
    display: flex;
    justify-content: end;
    margin-top: 50px;
}

.choose-events-year .active {
    color: var(--green);
}

.events-pictures {
    margin-top: 40px;
    max-width: 1593px;
    /* height: 1000px; */
}


/* ---------------- GRID ---------------- */

.grid {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    height: 100%;
}

.image1 {
    grid-area: 1 / 1 / 4 / 4;
}

.image2 {
    grid-area: 1 / 4 / 4 / 7;
}

.image3 {
    grid-area: 1 / 7 / 7 / 10;
}

.image4 {
    grid-area: 7 / 7 / 10 / 10;
}

.image5 {
    grid-area: 4 / 1 / 10 / 7;
}

.image6 {
    grid-area: 10 / 1 / 13 / 4;
}

.image7 {
    grid-area: 10 / 4 / 13 / 10;
}

.grid-image {
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    border-radius: 5px;
    padding-bottom: 25px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    overflow: hidden;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-image::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform .3s ease-in-out;
}

.grid-image:hover::after {
    transform: scale(1.05);
}

.image-heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 131%;
    color: var(--black);
    text-align: center;
    z-index: 1;
    bottom: 30px;
    transition: .3s ease-in-out;
    width: 100%;
    opacity: 0;
}

.grid-image:hover .image-heading {
    color: var(--white);
    opacity: 1;
}

/* ---------------- GRID ---------------- */


#event .container {
    padding-top: 210px;
}


.link-back a {
    text-decoration: none;
}

.link-back img {
    margin-right: 16px;
}

.event-name {
    color: var(--green);
    margin-top: 12px;
}

.image-stack-container {
    width: 700px;
    height: 700px;
}

.event-image-carousel {
    box-shadow: 0px 7px 50px -9px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 0px 7px 50px -9px rgba(0, 0, 0, 1) !important;
    -moz-box-shadow: 0px 7px 50px -9px rgba(0, 0, 0, 1);
    max-width: 100%;
    height: auto;
}

.carousel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-btn button {
    background: transparent;
    border: none;
}

.carousel-btn button svg path {
    color: var(--grey);
}

.carousel-btn button:hover svg path {
    color: var(--white);
}

.carousel-btn button:active svg path{
    color: var(--green);
}

.image-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 131%;
    color: var(--grey);
    padding-bottom: 200px;
}

.image-container {
    padding-top: 100px;
}


.random-events-heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 131%;
    margin-bottom: 40px;
}


.random-events-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-column-gap: 10px;
    overflow: auto;

}

.random-events-box {
    height: 327px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 25px;
}

a.random-events-box {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 131%;
    text-decoration: none;
    color: var(--black);
    text-align: center;
}

.overflow-hidden{
    overflow: hidden;
}

a.random-events-box:hover {
    color: var(--green);
}

.gallery-image{
    height: 400px;
}

.gallery-image img{
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
}


.random {
    height: 327px;
    width: 100%;
}

.background1,
.background2 {
    position: absolute;
    z-index: -1;
}

.background1 {
    top: -200px;
    left: -250px;
}

.background2 {
    top: 400px;
    right: 0;
}

.swiper {
    width: 100%;
    height: fit-content;
}

.format1 {
    width: 60px;
    height: 60px;
    cursor: pointer;
}

.format2 {
    width: 154px;
    height: 62px;
    cursor: pointer;
}

.picture-gallery {
    padding: 50px 0px;
}


.view-format {
    display: flex;
    justify-content: end;
    margin-top: 80px;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--green);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--green) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

@media only screen and (max-width: 1440px){
    .random{
        max-width:100%;
        height: 287px;
    }
}


@media only screen and (max-width: 1200px) {
    .visiting-date {
        font-size: 30px !important;
    }

    .visiting-name h4 {
        font-size: 40px !important;
    }

    .visiting a {
        font-size: 20px;
        text-decoration: none!important;
    }

    .visiting-place {
        font-size: 30px !important;
    }

    .visiting-box {
        padding-bottom: 70px;
    }
}


.linear-viewmode{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1032;
    background-color: rgba(37, 37, 37, 0.7);
}

.linear-image{
    height: 100%;
    width: 70%;
    margin-inline: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-group{
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linear-image img{
    max-height: 90%;
    max-width: 100%;
}

.close-overlay{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .visiting-box {
        grid-template-columns: repeat(1, 1fr);
    }

    .visiting-image{
        padding-right: 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(10, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .image1 {
        grid-area: 2 / 1 / 6 / 3;
    }

    .image2 {
        grid-area: 1 / 3 / 5 / 5;
    }

    .image3 {
        grid-area: 5 / 3 / 8 / 5;
    }

    .image4 {
        grid-area: 8 / 3 / 11 / 5;
    }

    .image5 {
        grid-area: 6 / 1 / 10 / 3;
    }

    .image6 {
        grid-area: 1 / 1 / 5 / 3;
    }
}

@media only screen and (max-width: 768px) {

    .view-format svg{
        height: 30px;
        width: 30px;
    }

    .background1 {
        top: -100px;
        left: -100px;
        max-width:300px;
    }

    .manifesto-description{
        padding-inline: 0;
    }

    .visiting-date {
        font-size: 20px !important;
    }

    .visiting-name h4 {
        font-size: 30px !important;
    }

    .visiting a {
        font-size: 15px;
    }

    .visiting-place {
        font-size: 20px !important;
    }

    .visiting-box {
        padding-bottom: 40px;
    }

    .visiting {
        margin-top: 102px;
    }

    #events {
        margin-top: 150px;
    }

    p.body1 {
        font-size: 30px !important;
        padding-inline: 0;
    }

    .random .image-heading {
        font-size: 18px !important;
    }

    .random {
        width: 100%;
        max-height: 150px;
    }
}

@media only screen and (max-width: 500px) {
    .visiting-date {
        font-size: 16px !important;
    }

    .visiting-name h4 {
        font-size: 25px !important;
    }

    .visiting a {
        font-size: 12px;
    }

    .visiting-place {
        font-size: 16px !important;
    }

    .visiting-box {
        padding-bottom: 25px;
    }

    .visiting {
        margin-top: 45px;
    }

    .visiting-image {
        padding: 0;
    }

    .events-pictures {
        height: auto;
    }

    .event-btn {
        width: 250px;
        display: flex;
        justify-content: center;
    }

    .choose-events-btn {
        align-items: center;
        flex-direction: column;
    }

    .choose-events-btn div:first-child {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .grid {
        display: flex;
        flex-direction: column;
        height: auto;
        padding-inline: 16px;
    }

    .grid-image {
        height: 233px;
    }

    #events {
        margin-top: 90px;
    }

    .heading h1 {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 40px !important;
        line-height: 131% !important;
        padding-inline: 16px;
    }

    .manifesto-description {
        padding-inline: 16px;
    }

    .body1 {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 131%;
    }

    .choose-events-btn {
        margin-top: 45px;
    }

    .image-heading {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 131%;
    }
    .random {
        width: 100%;
        max-height: 110px;
    }
}

@media only screen and (max-width: 400px) {

    .background1 {
        top: -60px;
        width: 200px;
        left: -70px;
    }
    .grid {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .grid-image {
        height: 233px;
    }

    .image-heading {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 131%;
    }

    p.body1 {
        font-size: 16px !important;
        padding-inline: 0;
    }
    .random {
        width: 172px;
        max-height: 128px;
    }
}

@media only screen and (max-width: 370px) {
    #events {
        margin-top: 90px;
    }

    .heading h1 {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600 !important;
        font-size: 30px !important;
        line-height: 102% !important;
        letter-spacing: -0.015em;
        padding-inline: 16px;
    }

    .manifesto-description {
        padding-inline: 16px;
    }

    .body1 {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 131%;
    }

    .choose-events-btn {
        margin-top: 40px;
    }
}