.polygon {
    background-size: cover!important;
    background-repeat: no-repeat!important;
    width: 1088px!important;
    height: 305px;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 40px;
    background: url('./background.svg');
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    z-index: 1;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
}

.polygon a{
    text-decoration: none;
}

.ps-text{
    max-width: 876px!important;
}

.mt-text-ps{
    margin-top: 40px;
}

.ppsbutton:hover .section-link-text{
    transition-duration: 0.2s;
    color:var(--black)!important;
    background-color: var(--green);
    padding: 0 20px 0 20px;
    border-radius: 5px;
    animation: bounce 0.5s;
}

@keyframes bounce {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.section-link-text{
    transition-duration: 0.2s;
}

line{
    transition-duration: 0.2s;
}

.ppsbutton:hover svg line{
    stroke: var(--black)!important;
    transition-duration: 0.2s;
}

.ppsbutton:hover .section-link-text {
    color: var(--black);
    transition-duration: 0.2s;
}