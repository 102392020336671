.lead-img {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    transition-duration: 1s;
}

.link-button:hover {
    background-color: var(--white);
    transition-duration: 1s;
    color: var(--light-black);
    fill:var(--light-black);
}

.linkedin{
    fill: var(--white);
    transform: scale(0.6);
}

.twitter{
    transform: scale(0.6);
    stroke: white;
    strokeWidth: 25;
}

.link-button:hover .linkedin {
    fill: var(--light-black);
}

.linkedin svg path {
    color: var(--white);
}

.link-button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--light-black);
    color: var(--light-black);
    transition: 1s;
}


.links-buttons {
    position: absolute;
    display: flex;
    bottom: -40px;
    right: -45px;
}

.lead-job {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 131%;
}

.lead-info{
    margin-top: 60px!important;
}

.founders-container{
    margin-top: 100px;
    max-width: 960px!important;
}

.founder-info{
    margin-top: 60px;
    padding: 40px;
    background-color: rgba(33, 30, 45, 1);
    border-radius: 5px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.founders-details{
    max-width: 425px;
}

.founder-name h6{
    text-align: center;
    color: var(--green);
    white-space: nowrap;
}

.social-btn{
    padding: 10px;
    background-color: var(--light-black);
    border-radius: 5px;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    color: var(--white);
}

.founder-socials {
    margin-top: 16px;
}

.founder-description{
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.founder-description p{
    max-width: 350px;
    transition: 0.3s;
}

.founder-description p:hover{
    opacity: 1;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
}

.founder-description p span{
    color: var(--green);
}

.founders-descriptions{
    display: flex;
    justify-content: space-between;
}

.social-btn:hover{
    background-color: var(--white);
    color: var(--light-black);
}

.social-btn:hover svg{
    fill: var(--light-black);
}

.founder-1{
    position: relative;
    top: -130px;
    left: -60px;
}

.founder-2{
    position: relative;
    top: -250px;
    right: -60px;
}

@media only screen and (max-width: 1080px){
    .founder-1,.founder-2{
        top:0;
        left: 0;
        right: 0;
    }
    .founder-info{
        width: auto;
        border:none;
        height: auto;
    }
}

@media only screen and (max-width: 769px){
    .founder-info{
        background-color: var(--background);
    }
}