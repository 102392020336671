@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap');

@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli/Muli.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Work_Sans";
  src: url("./fonts/Work_Sans/WorkSans-VariableFont_wght.ttf");
}

/* colors */

:root {
  --white: #fff;
  --black:  #0F0B13;
  --green: #A8FF35;
  --light-black: #211E2D;
  --dark-blue: #1F1B24;
  --background: #0F0B13;
  --grey: #86888B;
  --light-grey: #DDDDDD;
}


/* style  */

html{
  position: relative;
}

.overflow-inferit{
  overflow: inherit!important;
}

#main-intro {
  width: 948px;
}

.project {
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 70px 50px !important;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  transition: all;
  transition-duration: 1s;
  transition-delay: 0.2s;
  background-position: right, bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

body {
  font-family: 'Mulish' !important;
  background-color: var(--background) !important;
  color: var(--white) !important;
}

.new-section {
  margin-top: 150px !important;
  overflow: hidden;
}

.mt-80 {
  margin-top: 80px !important;
  overflow: hidden;
}

.section-heading {
  font-size: 80px;
  font-family: 'Mulish';
  line-height: 88px;
  text-align: center;
}

.section-heading-secondary {
  text-align: center;
}

.section-text {
  font-family: 'Mulish';
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.section-text-primary {
  font-family: 'Mulish';
  font-size: 30px;
  line-height: 54px;
  text-align: center;
}

.section-text-big {
  font-size: 40px;
}

.section-link-text {
  color: var(--green);
}

.section-margin-big {
  margin-top: 250px;
}

.section-text-big {
  font-family: 'Mulish';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 36px;
  line-height: 135%;
  text-align: center;
}

.section-heading-big {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 102%;
}


.section-text-margin-65 {
  margin-top: 65px !important;
}

.section-margin-100 {
  margin-top: 100px !important;
}


.section-margin-160 {
  margin-top: 160px !important;
}

.section-margin-260 {
  margin-top: 260px !important;
}

.section-margin-200 {
  margin-top: 200px !important;
}

.arrowsDown {
  width: 90px;
}

#info-container {
  max-width: 1560px;
  margin: auto;
}

#main {
  width: 100%;
  height: 715px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#video-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 715px;
  object-fit: cover;
  filter: brightness(0.5);
}

#picture-background {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 715px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.info-circle-text {
  padding: 40px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px !important;
  height: 380px !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.info-circle-text.blur{
  background-color: var(--black);
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.body4 .ps-section {
  font-size: 40px;
}

.projects-align-text {
  text-align: center;
}

#border-gradient {
  width: 3px;
  border-radius: 10px;
  background: linear-gradient(#AFFD4B, #99CC61, #E8E8E8);
  margin-right: 27.5px;
  height: 182px;
}

#info-container-heading {
  margin: auto;
  margin-bottom: 0;
  max-width: 1272px;
}



/* fonts */

h1 {
  font-family: 'Work_Sans';
  font-style: normal;
  font-weight: 500!important;
  font-size: 100px!important;
  line-height: 102%!important;
  letter-spacing: -0.035em;
}

h2 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 100px !important;
  line-height: 102% !important;
  letter-spacing: -0.035em;
}

h3 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 80px !important;
  line-height: 110% !important;
  letter-spacing: -0.015em;
}

h4 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 50px !important;
  line-height: 102% !important;
  letter-spacing: -0.015em;
}

h5 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 131%;
  letter-spacing: -0.015em;
}

h6 {
  font-family: 'Work_Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 131% !important;
}

.body1 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 131%;
  letter-spacing: -0.015em;
}

.body2 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 131%;
  letter-spacing: -0.035em;
}

.body3 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 135%;
}

.body4 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 135%;
}

.body5 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 150%;
}

.body6 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
}

.body7 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 131%;
  letter-spacing: -0.015em;
}

.body8 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
}

.body9 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.055em;
}

.body10 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
}

.margin-top-lead {
  margin-top: 0;
}

.section-projects {
  height: 600px;
  padding-inline:10px;
}

.mr-link {
  margin-right: 38px;
}

.nav-margins {
  margin-left: 280px !important;
  margin-right: 280px !important;
}

.past-plan{
  opacity: .4;
}

@media only screen and (max-width: 1441px) {
  .project-card-logo svg, .project-card-logo img{
    max-width: 300px;
  }

  .project-grid {
    max-width: 1390px !important;
  }

  .logo {
    max-width: 300px !important;
  }

  .polygon {
    max-width: 1094px !important;
  }

  .info-circle-text {
    max-width: 332px !important;
    max-height: 322px !important;
  }

  .circle-image {
    max-width: 405px;
    max-height: 405px;
  }

  #main,
  #video-background,
  #picture-background {
    height: 550px;
  }

  .project {
    background-position: bottom, right !important;
  }

  .section-margin-260 {
    margin-top: 150px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .project-grid {
    grid-template-columns: repeat(1, 3fr) !important;
    justify-items: center;
    margin-top: 100px !important;
  }
  .project-card{
    margin-bottom: 50px;
  }
  .section-projects{
    height: auto;
  }
}

@media only screen and (max-width: 1419px) {
  .project-card{
    padding: 30px 30px;
  }
}

@media only screen and (max-width: 1401px) {
  #info-container {
    max-width: 1440px;
  }

  .project {
    padding: 50px 30px !important;
  }

  .section-text-margin-65 {
    margin-top: 60px !important;
  }
}

@media only screen and (max-width: 1313px){
  .project-grid {
    grid-template-columns: repeat(1, 3fr) !important;
    justify-items: center;
    margin-top: 100px !important;
  }
  .project-card{
    margin-bottom: 20px;

  }
  .section-projects{
    height: auto;
    width: 650px;
  }
}

@media only screen and (max-width: 1200px) {
  .margin-top-lead {
    margin-top: 80px !important;
  }

  .section-margin-160 {
    margin-top: 80px !important;
  }

  .logo {
    width: 200px !important;
  }
}

@media only screen and (max-width: 992px) {
  .section-projects {
    height: 100%;
  }
  .project{
    width: 600px!important;
    margin-bottom: 30px;
    background-position: right!important;
  }
  .founder-name h6{
    font-size: 28px!important;
  }
}

@media only screen and (max-width: 769px) {

  .section-projects{
    width: 450px;
  }
  .body7{
    font-size: 20px!important;
  }

  .founder-name h6{
    font-size: 40px!important;
  }
  .project {
    width: 465px !important;
    margin-bottom: 30px;
  }

  .project-heading {
    font-size: 50px !important;
  }

  .projects-text {
    font-size: 20px !important;
    max-width: 535px !important;

  }

  .section-text-margin-65 {
    margin-top: 30px !important;
  }

  .psBottomImage {
    height: 185px !important;
    width: 500px !important;
    z-index: 0;
  }

  .arrowsDownPs {
    width: 16px !important;
  }

  .ps-text {
    max-width: 582px;
  }

  .polygon {
    max-width: 640px !important;
    max-height: 178px !important;
    padding-bottom: 25px !important;
  }

  .mt-text-ps {
    margin-top: 18px !important;
  }

  h4.section-heading-secondary {
    font-size: 45px !important;
  }

  #info-container-heading {
    max-width: 535px;
  }

  #info-container {
    max-width: 768px;
  }

  #main-intro {
    width: 535px;
  }

  .info-circle-text {
    max-width: 342px;
    max-height: 342px;
  }

  #border-gradient {
    height: 120px;
    margin-right: 18.5px;
  }

  .nav-margins {
    margin-left: 117px !important;
    margin-right: 117px !important;
  }

  h4 {
    font-size: 30px !important;
  }

  .projects-align-text {
    text-align: left;
  }

  h2 {
    font-size: 50px;
  }

  h6 {
    font-size: 45px !important;
  }

  .body2 {
    font-size: 22px;
  }

  .body3 {
    font-size: 24px !important;
  }

  .body4 {
    font-size: 20px !important;
  }

  .body4 .ps-section {
    font-size: 25px !important;
  }

  .arrowsDown {
    width: 64px;
  }

  .new-section {
    margin-top: 100px !important;
  }

  #main,
  #video-background,
  #picture-background {
    height: 300px;
  }

  h3 {
    font-size: 40px !important;
  }

  .section-margin-big {
    margin-top: 150px;
  }

  .arrowDownPs svg {
    margin-bottom: 2px;
    width: 16px !important;
    height: 16px !important;
  }
  #leads h2{
    font-size: 50px!important;
  }
  .founders-descriptions{
    flex-direction: column;
  }
  .founder-info{
    justify-content: center;
    margin-top: 70px!important;
  }
  .project-card-logo svg{
    width: auto!important;
  }
}

@media only screen and (max-width: 481px) {
  .section-projects{
    height: auto;
    width: 440px;
  }
  .founder-info{
    padding: 0!important;
  }
  .project-card{
    margin-bottom: 40px;
    height: 250px!important;
    padding: 40px 30px!important;
  }
  .project-card:hover{
    height: 320px!important;
  }
  .project-card-logo svg, .project-card-logo img{
    max-width: 250px;
    height: 30px;
  }
  .project-card-logo .company-logo{
    position: initial;
    height: 60px;
    width: auto;
  }
  .project-box:hover .project{
   max-height: 250px!important;
   height: 250px!important;
  }
  .founders-container{
    margin-top: 60px!important;
  }
  .founders-container h6{
    font-size: 36px!important;
  }
  .project-grid {
    margin-top: 50px !important;
  }

  .project {
    width: 100%!important;
    height: 250px !important;
    background-position: right !important;
  }

  .project-box{
    width: 100%;
    padding-inline: 20px;
  }

  .project-links-link {
    margin-top: 18px !important;
  }

  .logo {
    max-width: 168px !important;

  }

  .project-card-description{
    margin-top: 50px;
  }

  .logoArrow {
    width: 12px;
    height: 12px;
  }

  .project:hover {
    max-height: 320px !important;
  }

  .psBottomImage {
    height: 148px !important;
    width: 400px !important;
  }

  .ps-text {
    max-width: 406px;
  }

  .polygon {
    max-width: 430px !important;
    max-height: 135px !important;
    padding-bottom: 23px !important;
  }

  .mt-text-ps {
    margin-top: 13px !important;
  }

  h4.section-heading-secondary {
    font-size: 30px !important;
  }

  .info-circle-text {
    width: 250px!important;
    height: 250px!important;
  }

  .circle-image {
    width: 315px!important;
    height: 350px!important;
  }

  #info-container-heading {
    max-width: 448px;
  }

  #info-container {
    max-width: 480px;
  }

  #main-intro {
    width: 400px;
  }

  #border-gradient {
    height: 80px;
    margin-right: 9.5px;
  }

  .nav-margins {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .section-margin-big {
    margin-top: 80px;
  }

  .project {
    padding: 40px 30px !important;
  }

  .project-heading {
    font-size: 40px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  .body3 {
    font-size: 16px !important;
  }

  .body8 {
    font-size: 16px !important;
  }

  h6 {
    font-size: 30px !important;
  }

  .body2 {
    font-size: 16px;
  }

  .body4 {
    font-size: 12px !important;
  }

  .body4 .ps-section {
    font-size: 15px !important;
  }

  .arrowsDown {
    width: 25px;
  }

  .new-section {
    margin-top: 80px !important;
  }

  #welcome {
    margin-top: 50px;
  }

  #main,
  #video-background,
  #picture-background {
    height: 175px;
  }

  h3 {
    font-size: 28px !important;
  }

  .section-margin-260 {
    margin-top: 100px !important;
  }

  .projects-text {
    max-width: 400px !important;
    padding: 0 !important;
  }
  #main,#picture-background{
    margin-top: 30px!important;
  }
}

@media only screen and (max-width: 481px) {
  .section-projects{
    width: 360px;
  }
}

@media only screen and (max-width: 360px) {
  .section-projects{
    width: 320px;
  }
}

@media only screen and (max-width: 321px) {
  .section-projects{
    width: 288px;
  }
  .project-card{
    margin-bottom: 20px;
    height: 260px!important;
    padding: 22px 30px!important;
  }
  .project-card:hover{
    height: 300px!important;
  }
  .project-card-logo svg,.project-card-logo img{
    max-width: 160px;
  }
  .project-card-arrow svg{
    width: 12px;
  }
  .project-heading {
    font-size: 50px !important;
  }
  .founder-info{
    padding-inline: 0!important;
    margin-top: 40px!important;
  }
  .founders-container{
    margin-top: 50px!important;
  }
  .founders-container h6{
    font-size: 30px!important;
  }
  .founders-image img{
    max-width: 288px!important;
  }
  .founders-descriptions{
    padding-inline: 10px;
  }


  .project-grid {
    margin-top: 30px !important;
  }

  .projects-text {
    max-width: 288px !important;
  }

  .section-text-margin-65 {
    margin-top: 20px !important;
  }

  .mt-text-ps {
    margin-top: 8px !important;
  }

  .psBottomImage {
    height: 111px !important;
    width: 300px !important;
  }

  .ps-text {
    max-width: 272px;
  }

  .polygon {
    max-height: 120px !important;
    max-width: 288px !important;
    padding-bottom: 15px !important;
  }

  .info-circle-text {
    width: 210px !important;
    height: 210px !important;
  }

  .circle-image {
    width: 270px !important;
    height: 300px !important;
  }

  #info-container-heading {
    max-width: 288px;
  }

  #info-container {
    max-width: 320px;
  }

  #main-intro {
    width: 288px;
  }

  #border-gradient {
    height: 60px;
  }

  .section-margin-big {
    margin-top: 60px;
  }

  h4 {
    font-size: 16px !important;
  }

  h6 {
    font-size: 30px !important;
  }

  .body2 {
    font-size: 14px;
  }

  .body7 {
    font-size: 16px !important;
  }

  .new-section {
    margin-top: 60px !important;
  }

  #main,
  #video-background,
  #picture-background {
    height: 120px;
  }

  #picture-background {
    background-position: bottom;
  }

  h3 {
    font-size: 20px !important;
  }

  .section-margin-260 {
    margin-top: 80px !important;
  }

  #welcome {
    margin-top: 50px !important;
  }
  .logo-header{
    width: 80px!important;
  }
  nav{
    padding: 0;
  }
  #main,#picture-background{
    margin-top: 40px!important;
  }
}

@media (min-width: 1400px){
  .container{
    max-width: 1374px!important;
  }  
}
