.info-circle {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
}

#power img{
    animation: rotateLeft 40s linear infinite;
}

#storage img{
    animation: rotateRight 40s linear infinite;
}

#movement img{
    animation: rotateLeft 40s linear infinite;
}


.circle-logo-advantage {
    position: relative;
}

.circle-image {
    width: 500px;
    height: 500px;
}


@keyframes rotateLeft {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

@keyframes rotateRight {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes rotateLeft {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}


@media only screen and (max-width: 1200px) {
    .info-cirlce-text-box:not(:first-child) {
        margin-top: 20px;
    }
    .info-circle{
        grid-template-columns: repeat(1, 3fr);
        grid-template-rows: 3fr;
    }
}