nav {
    background-color: var(--background) !important;
    padding-top: 15px!important;
    padding-bottom: 20px!important;
}

nav .container-banx {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

nav a {
    position: relative;
    color: var(--white);
    text-decoration: none;
}

nav a.active {
    color: var(--green) !important;
}

nav a:hover{
    color:var(--white);
}

.collapsed .link {
    margin-top: 16px !important;
}

.collapsed .link:first-child {
    margin-top: 0 !important;
}

.collapsed {
    padding: 22px 16px 22px 16px;
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    z-index: 3;
    display: flex;
    background-color: var(--black);
}

.close-icon {
    position: absolute;
    top: 8px;
    right: 16px;
}

.nav-icon {
    cursor: pointer;
}

.overlay {
    width: 100%;
    display: flex;
    height: 100vh;
    background: rgba(56, 56, 56, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

nav a::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: var(--green);
    overflow: hidden;
    width: 0%;
    transition: 0.3s;
}

nav a:hover:before{
    width: 100%;
}
